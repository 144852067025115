/* eslint import/prefer-default-export: 0 */
import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import mixpanel from 'mixpanel-browser';
import history from '../utils/history';
import { deleteCookie } from '../utils/cookies';

// Not authenticated succeeded
export function notAuthenticated() {
  return { type: 'NOT_AUTHENTICATED' };
}

// Login succeeded
export function loginSucceeded(data) {
  try {
    Bugsnag.setUser(data?.user?.id, data?.user?.email, data?.user?.name);
  } catch {
    // there was an error while appending user to Bugsnag
  }

  mixpanel.identify(data?.user?.id);
  mixpanel.people.set({
    name: data?.user?.name,
    email: data?.user?.email,
    client: data?.user?.client?.name,
    client_id: data?.user?.client?.id,
  });

  return { type: 'LOGIN_SUCCEEDED', payload: data };
}

// Logout
export function logout() {
  return dispatch => {
    dispatch({ type: 'LOGOUT_STARTED' });
    axios.post('/logout').then(() => {
      axios.defaults.headers.common.Authorization = null;
    });
  };
}

export function logoutCookie() {
  return dispatch => {
    dispatch({ type: 'LOGOUT_COOKIE' });
    deleteCookie('bb_token');
  };
}

export function logoutFinished(redirect = false) {
  if (redirect) {
    history.push('/goodbye');
  }
  return { type: 'LOGOUT_FINISHED' };
}

export function updateClient(data) {
  return { type: 'CLIENT_UPDATED', data };
}
